import {
    AppBar,
    Button,
    IconButton,
    Toolbar,
    useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import FlexBetween from "../../components/FlexBetween";

const Topbar = ({ isSidebarOpen, setIsSidebarOpen }) => {
    const theme = useTheme()
    return (
        <AppBar
            sx={{
                position: "static",
                background: "white",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
            }}
            className="hey"
        >
            <Toolbar sx={{ justifyContent: "space-between" }}>
                <FlexBetween>
                    <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                        <MenuIcon sx={{
                            fontSize: "30px",
                            cursor: "pointer",
                            color: theme.palette.primary[500],
                        }} />
                    </IconButton>
                </FlexBetween>
            </Toolbar>
        </AppBar>
    );
};

export default Topbar;
