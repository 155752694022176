// ForgotPassword.js
import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, CircularProgress, Divider, Alert, Button, useTheme, TextField } from '@mui/material';
import axios from 'axios';
import { API_URL } from '../../constant';
import { CloseIcon } from '../../Icons';
import Heading from '../common/Heading/Heading';

const ForgotPassword = ({ open, onClose }) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const theme = useTheme();


    const handleSendEmail = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(`${API_URL}/api/auth/request-password-reset`, { email });
            setEmail('')
            setError(null)
            setSuccess(response.data.message);
        } catch (err) {
            if (err.response && err.response.data) {
                if (err.response.data.display_errors && err.response.data.display_errors.length > 0) {
                    setError(err.response.data.display_errors[0]);
                } else {
                    setError('An unexpected error occurred.');
                }
            } else {
                setError('An unexpected error occurred.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setEmail('');
        setError(null);
        onClose();
        setSuccess(null)
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                <div className="flex justify-between items-center">
                    <Heading title={"Forgot Password"} />
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Divider sx={{ mt: 1 }} />
            </DialogTitle>
            <form className='-mt-3' onSubmit={handleSendEmail}>
                <DialogContent>
                    {
                        (success || error) &&
                        <div className='mb-4'>
                            {success && <Alert severity="success">{success}</Alert>}
                            {error && <Alert severity="error">{error}</Alert>}
                        </div>
                    }
                    <div>
                        <label htmlFor="email" className="block text-sm text-left font-medium leading-6 text-gray-900">Email address</label>
                        <div>
                            <TextField
                                id="email"
                                // label="Email address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                placeholder="Enter email address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                fullWidth
                                variant="outlined"
                                margin="normal"
                            />
                        </div>
                    </div>

                </DialogContent>
                <DialogActions>
                    <div className="w-full mb-4 px-4">
                        <div>
                            <Button disabled={loading} type="submit" sx={{ width: "100%", background: theme.palette.primary[500], color: "white" }} variant="contained"> {loading ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                'Send Reset Link'
                            )}</Button>

                        </div>
                    </div>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default ForgotPassword;
