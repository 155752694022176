/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Tab, Tabs, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { a11yProps, CustomTabPanel } from '../util';
import { Link } from 'react-router-dom';
import { HomeOutlinedIcon, LocalOfferOutlinedIcon } from '../Icons';
import Header from '../components/Header/Header';
import Deals from '../components/common/Deals';


const Home = () => {
    const [value, setValue] = useState(0);

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const theme = useTheme();

    const breadcrumbsLinks = [
        { id: 2, title: "Dashboard", href: "/home", icon: <HomeOutlinedIcon fontSize="small" /> },
    ];

    return (
        <Box m="0rem 2.5rem">
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Header breadcrumbsLinks={breadcrumbsLinks} />
                <Link to={`/offers/add`}>
                    <Button startIcon={<HomeOutlinedIcon />} sx={{ mx: 1, py: 1, background: theme.palette.primary[500], color: "white" }} variant="contained">
                        Add Offer
                    </Button>
                </Link>
            </Box>
            <Box

            >
                <Box sx={{ width: '100%', my: 3 }}>
                    <Typography sx={{ my: 2, fontSize: "20px", fontWeight: "bold", textAlign: "left" }}>Recently Added Offers</Typography>
                    <Deals paginationModel={paginationModel} value={"recently_added"} setPaginationModel={setPaginationModel} />
                </Box>
                <Box sx={{ width: '100%', my: 6 }}>
                    <Typography sx={{ my: 2, fontSize: "20px", fontWeight: "bold", textAlign: "left" }}>New Deals</Typography>
                    <Deals paginationModel={paginationModel} value={"new_deals"} setPaginationModel={setPaginationModel} />
                </Box>
                <Box sx={{ width: '100%', my: 6 }}>
                    <Typography sx={{ my: 2, fontSize: "20px", fontWeight: "bold", textAlign: "left" }}>Hot Deals</Typography>
                    <Deals paginationModel={paginationModel} value={"hot_deals"} setPaginationModel={setPaginationModel} />
                </Box>
            </Box>
        </Box>
    );
};

export default Home;
