export const tokens = {
  grey: {
    100: "#f0f0f3",
    200: "#e1e2e7",
    300: "#d1d3da",
    400: "#c2c5ce",
    500: "#b3b6c2",
    600: "#8f929b",
    700: "#6b6d74",
    800: "#48494e",
    900: "#242427",
  },
  primary: {
    10: "#f2f2f7",
    50: "#f2f2f7",
    100: "rgb(234,240,254)",
    200: "#b9ccf4",
    300: "#97b3ef",
    400: "#7499e9",
    500: "#2000B1",
    600: "#4166b6",
    700: "black",
    800: "#20335b",
    900: "#101a2e",
  },
  secondary: {
    // yellow
    100: "#fcf0dd",
    200: "#fae1bb",
    300: "#f7d299",
    400: "#f5c377",
    500: "#f2b455",
    600: "#c29044",
    700: "#916c33",
    800: "#614822",
    900: "#302411",
  },
  tertiary: {
    // purple
    500: "#8884d8",
  },
  fourth: {
    100: "#ffebee", // Light red
    200: "#ffcdd2", // Slightly darker light red
    300: "#ef9a9a", // Medium light red
    400: "#e57373", // Medium red
    500: "#f44336", // Standard red
    600: "#e53935", // Dark red
    700: "#d32f2f", // Darker red
    800: "#c62828", // Very dark red
    900: "#b71c1c", // Deep dark red
  },
  background: {
    light: "#FFFFFF",
    main: "#FFFFFF",
    antiFlashWhite: "#F2F3F4",
    midnightBlue: "#020035",
    laceCap: "#EBEAED",
    kimchi: "#ED4800",
    darkRoyalty: "#02066F",
    deepSea: "#2000B1",
  },
};

// mui theme settings
export const themeSettings = {
  palette: {
    primary: {
      ...tokens.primary,
      main: tokens.primary[500],
      light: tokens.primary[400],
    },
    secondary: {
      ...tokens.secondary,
      main: tokens.secondary[500],
    },
    tertiary: {
      ...tokens.tertiary,
    },
    fourth: {
      ...tokens.fourth,
    },
    grey: {
      ...tokens.grey,
      main: tokens.grey[500],
    },
    background: {
      default: tokens.background.main,
      light: tokens.background.light,
      antiFlashWhite: tokens.background.antiFlashWhite,
      midnightBlue: tokens.background.midnightBlue,
      laceCap: tokens.background.laceCap,
      kimchi: tokens.background.kimchi,
      darkRoyalty: tokens.background.darkRoyalty,
      deepSea: tokens.background.deepSea,
    },
  },
  typography: {
    fontFamily: "unset",
    fontSize: 12,
    h1: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 32,
    },
    h2: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 24,
    },
    h3: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 20,
      fontWeight: 800,
      color: tokens.grey[200],
    },
    h4: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 14,
      fontWeight: 600,
      color: tokens.grey[300],
    },
    h5: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 12,
      fontWeight: 400,
      color: tokens.grey[500],
    },
    h6: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 10,
      color: tokens.grey[700],
    },
  },
};
