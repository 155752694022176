import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Sidebar from '../global/Sidebar';
import Topbar from '../global/Topbar';

const Layout = (props) => {
  const isNonMobile = useMediaQuery('(min-width: 600px)');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    setIsSidebarOpen(isNonMobile);
  }, [isNonMobile]);

  return (
    <Box display={isNonMobile ? "flex" : "block"} height="100%">
      {/* Sidebar */}
      <Sidebar
        isNonMobile={isNonMobile}
        drawerWidth="250px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      {/* Main content area */}
      <Box
        sx={{
          flexGrow: 1,
          marginLeft: isSidebarOpen ? '' : '0',
          overflow: 'hidden',
        }}
      >
        <Topbar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
        <Box sx={{ padding: '16px 0px', overflow: 'auto' }}> {/* Add padding to main content */}
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
