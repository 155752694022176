import {  Breadcrumbs, Chip, emphasize, styled } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Header = ({ breadcrumbsLinks }) => {

  const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
      theme.palette.mode === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[800];
    return {
      padding: theme.spacing(1.7, 1),
      fontSize: theme.typography.pxToRem(16),
      cursor: "pointer",
      backgroundColor,
      height: theme.spacing(3),
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightRegular,
      '&:hover, &:focus': {
        backgroundColor: emphasize(backgroundColor, 0.06),
      },
      '&:active': {
        boxShadow: theme.shadows[1],
        backgroundColor: emphasize(backgroundColor, 0.12),
      },
    };
  });

  function handleClick(event) {
    event.preventDefault();
  }

  return (
    <div role="presentation" onClick={handleClick} style={{margin:"25px 0px"}}>
      <Breadcrumbs aria-label="breadcrumb">
        {breadcrumbsLinks?.map((link, index) => {
          return (
            <Link to={link.href} key={index}>
            <StyledBreadcrumb
              key={index}
              label={link.title}
              icon={link.icon}
            />
          </Link>
          );
        }
        )}
      </Breadcrumbs>
    </div>
  );
};

export default Header;
