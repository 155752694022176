import { Navigate, Route, Routes } from 'react-router-dom'; // Note: use 'react-router-dom'
import './App.css';
import Home from './pages/Home';
import Login from './components/Auth/Login';
import ResetPassword from './components/Auth/ResetPassword';
import Layout from './scenes/layout';
import { useMemo } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import { themeSettings } from './theme';
import Offers from './scenes/offers';
import AddOffers from './scenes/offers/AddOffers';
import { useAuth } from './context/AuthContext';
import EditOffers from './scenes/offers/EditOffers';
import ListCategories from './scenes/Category';
import AddCategory from './scenes/Category/AddCategory';
import EditCategory from './scenes/Category/EditCategory';
import Banner from './scenes/Banner/Banner';
import AddBanner from './scenes/Banner/AddBanner';
import EditBanner from './scenes/Banner/EditBanner';
import Terms from './components/Terms';

function App() {
  const theme = useMemo(() => createTheme(themeSettings), []);
  const { isLoggedIn } = useAuth();

  return (
    <div className="App">
     
        <ThemeProvider theme={theme}>
          <Routes>
            {isLoggedIn ? (
              <Route element={<Layout />}>
                <Route path="/" element={<Navigate to="/home" replace />} />
                <Route path="/home" element={<Home />} />
                <Route path="/offers" element={<Offers />} />
                <Route path="/category" element={<ListCategories />} />
                <Route path="/banner" element={<Banner />} />
                <Route path="/banner/add" element={<AddBanner />} />
                <Route path="/offers/add" element={<AddOffers />} />
                <Route path="/category/add" element={<AddCategory />} />
                <Route path="/offers/:id" element={<EditOffers />} />
                <Route path="/category/:id" element={<EditCategory />} />
                <Route path="/banner/edit/:id" element={<EditBanner />} />
              </Route>
            ) : (
              <>
                <Route path="/" element={<Login />} />
                <Route path="/terms-and-conditions" element={<Terms />} />
                <Route path="/reset-password/:email/:token" element={<ResetPassword />} />
              </>
            )}
          </Routes>
        </ThemeProvider>
    </div>
  );
}

export default App;
