import {
  Box,
  Button,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ChevronLeft,
  ExitToApp,
  ExpandLessOutlined,
  ExpandMoreOutlined,
} from "@mui/icons-material";

import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "../../components/FlexBetween.js";
import AUTH_LOGO_IMAGE from "../../assets/Images/Logo.png";
import {
  AddBoxOutlinedIcon,
  BrokenImageOutlinedIcon,
  CategoryOutlinedIcon,
  CottageOutlinedIcon,
  LocalOfferOutlinedIcon,
  SummarizeOutlinedIcon,
  ViewCompactAltOutlinedIcon,
} from "../../Icons/index.js";
import { useAuth } from "../../context/AuthContext.js";

const navItems = [
  {
    id: 1,
    name: "Dashboard",
    icon: <CottageOutlinedIcon />,
    path: "/home",
  },
  {
    id: 2,
    name: "Offers",
    icon: <LocalOfferOutlinedIcon />,
    path: "/offers",
    subroutes: [
      {
        id: 1,
        name: "List Offers",
        icon: <SummarizeOutlinedIcon />,
        path: "/offers",
      },
      {
        id: 2,
        name: "Add Offer",
        icon: <AddBoxOutlinedIcon />,
        path: "/offers/add",
      },
    ],
  },
  {
    id: 3,
    name: "Category",
    icon: <CategoryOutlinedIcon />,
    path: "/category",
    subroutes: [
      {
        id: 1,
        name: "List Categories",
        icon: <SummarizeOutlinedIcon />,
        path: "/category",
      },
      {
        id: 2,
        name: "Add Category",
        icon: <AddBoxOutlinedIcon />,
        path: "/category/add",
      },
    ],
  },
  {
    id: 4,
    name: "Banner",
    icon: <BrokenImageOutlinedIcon />,
    path: "/banner",
    subroutes: [
      {
        id: 1,
        name: "View Banners",
        icon: <ViewCompactAltOutlinedIcon />,
        path: "/banner",
      },
      {
        id: 2,
        name: "Add Banner",
        icon: <AddBoxOutlinedIcon />,
        path: "/banner/add",
      },
    ],
  },
];

const Sidebar = ({
  isNonMobile,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
}) => {
  const theme = useTheme();
  const [active, setActive] = useState("dashboard");
  const [offersOpen, setOffersOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [bannerOpen, setBannerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setActive(location.pathname.substring(1));
  }, [location]);

  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          variant="persistent"
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          anchor="left"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              color: theme.palette.text.primary,
              backgroundColor: "white",
              width: drawerWidth,
              borderWidth: isNonMobile ? "0px" : "2px",
              boxSizing: "border-box",
              boxShadow: "0px 20px 10px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <Box m="1.5rem 2rem 2rem 3rem">
            <FlexBetween color="primary.main">
              <Box display="flex" alignItems="center" gap="0.5rem">
                <Typography
                  variant="h2"
                  fontWeight="bold"
                  sx={{ p: 2 }}
                  color={theme.palette.primary.dark}
                >
                  <img className="logoImg" src={AUTH_LOGO_IMAGE} alt="Logo" />
                </Typography>
              </Box>
              {!isNonMobile && (
                <IconButton onClick={() => setIsSidebarOpen(false)}>
                  <ChevronLeft sx={{ color: theme.palette.primary[500] }} />
                </IconButton>
              )}
            </FlexBetween>
          </Box>
          <List
            sx={{
              flexGrow: 1,
              width: "100%",
              maxWidth: 360,
              p: 2,
              "& .Mui-selected": {
                backgroundColor: theme.palette.primary[100],
                color: theme.palette.primary[500],
                "& .MuiListItemIcon-root": {
                  color: theme.palette.primary[500],
                },
              },
            }}
            component="nav"
          >
            {navItems.map((route) => {
              return (
                <div key={route.id}>
                  {route.subroutes ? (
                    <>
                      <ListItemButton
                        sx={{ mb: 0.5 }}
                        onClick={() => {
                          // Toggle the dropdown for the specific route
                          if (route.name === "Offers") {
                            setOffersOpen(!offersOpen);
                          } else if (route.name === "Category") {
                            setCategoryOpen(!categoryOpen);
                          } else if (route.name === "Banner") {
                            setBannerOpen(!bannerOpen);
                          }
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            // background: theme.palette.primary[500],
                            // color={
                            //     active === route.path.substring(1)
                            //       ? theme.palette.primary[700]
                            //       : theme.palette.grey[700]
                            //   }
                            color:
                              active === route.path.substring(1) &&
                              theme.palette.background.deepSea,
                          }}
                        >
                          {route.icon}
                        </ListItemIcon>
                        <ListItemText primary={route.name} />
                        {route.name === "Offers" &&
                          (offersOpen ? (
                            <ExpandLessOutlined />
                          ) : (
                            <ExpandMoreOutlined />
                          ))}
                        {route.name === "Category" &&
                          (categoryOpen ? (
                            <ExpandLessOutlined />
                          ) : (
                            <ExpandMoreOutlined />
                          ))}
                        {route.name === "Banner" &&
                          (bannerOpen ? (
                            <ExpandLessOutlined />
                          ) : (
                            <ExpandMoreOutlined />
                          ))}
                      </ListItemButton>
                      <Collapse
                        in={
                          route.name === "Offers"
                            ? offersOpen
                            : route.name === "Category"
                              ? categoryOpen
                              : bannerOpen
                        }
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {route.subroutes.map((subroute) => {
                            return (
                              <ListItemButton
                                key={subroute.id}
                                sx={{ pl: 4 }}
                                selected={active === subroute.path.substring(1)}
                                onClick={() => {
                                  navigate(subroute.path);
                                  setActive(
                                    location.pathname.substring(1) ||
                                    subroute.path.substring(1)
                                  );
                                }}
                              >
                                <ListItemIcon
                                  sx={{
                                    color:
                                      active === subroute.path.substring(1) &&
                                      theme.palette.background.deepSea,
                                  }}
                                >
                                  {subroute.icon}
                                </ListItemIcon>
                                <ListItemText primary={subroute.name} />
                              </ListItemButton>
                            );
                          })}
                        </List>
                      </Collapse>
                    </>
                  ) : (
                    <ListItemButton
                      selected={active === route.path.substring(1)}
                      onClick={() => {
                        navigate(route.path);
                        setActive(route.path.substring(1));
                      }}
                      sx={{
                        borderRadius: "0.5rem",
                        mb: 0.5,
                        backgroundColor:
                          active === route.path.substring(1)
                            ? "blue"
                            : "transparent",
                      }}
                    >
                      <ListItemIcon
                        color={
                          active === route.path.substring(1) &&
                          theme.palette.background.deepSea
                        }
                      >
                        {route.icon}
                      </ListItemIcon>
                      <Typography
                        variant="h4"
                        sx={{ py: 1 }}
                        color={
                          active === route.path.substring(1)
                            ? theme.palette.primary[700]
                            : theme.palette.grey[700]
                        }
                      >
                        {route.name}
                      </Typography>
                    </ListItemButton>
                  )}
                </div>
              );
            })}
          </List>

          <Box sx={{ mb: 2 }}>
            <FlexBetween textTransform="none" gap="1rem">
              <Box
                display="flex"
                alignItems="center"
                gap="0.5rem"
                sx={{ margin: "0px 70px" }}
              >
                <Link to="/" onClick={handleLogout}>
                  <Button
                    startIcon={<ExitToApp />}
                    sx={{
                      fontSize: "16px",
                      cursor: "pointer",
                      fontWeight: "bold",
                      padding:"5px 20px",
                      color: theme.palette.primary[500],
                    }}
                  >
                    Logout
                  </Button>
                </Link>
              </Box>
            </FlexBetween>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
