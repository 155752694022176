import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, IconButton, Divider, useTheme, Alert } from '@mui/material';
import Heading from '../common/Heading/Heading';
import { CloseIcon } from '../../Icons';

const DeleteOffers = ({ open, onClose, onConfirm, title, error }) => {
    const theme = useTheme()
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                <div className="flex justify-between items-center">
                    <Heading title={`Delete ${title}`} />
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Divider sx={{ mt: 1 }} />
            </DialogTitle>
            <DialogContent>
                {error && <div className="mb-2">
                    <Alert severity="error">{error}</Alert>
                </div>}
                <DialogContentText sx={{ fontSize: "14px"}}>
                    Deleting this {title} is a permanent action and cannot be undone. Are you sure you want to proceed?
                </DialogContentText>

            </DialogContent>
            <DialogActions>
                <div className='px-4 mb-4 '>
                    <Button onClick={onConfirm} type="submit" sx={{ py: 1, background: theme.palette.primary[500], color: "white" }} variant="contained"> Delete</Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteOffers;
