// src/components/CategoryDetails.js

import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Typography,
    Box,
    IconButton,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    useTheme,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import Heading from '../common/Heading/Heading';
import { API_URL } from '../../constant';



const CategoryDetails = ({ open, onClose, offer }) => {
    const theme = useTheme()
    if (!offer) return null;

    return (
        <Dialog open={open} maxWidth="md" fullWidth>
            <DialogTitle>
                <div className="flex justify-between items-center">
                    <Heading title={"Category Details"} />
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Divider sx={{ mt: 1 }} />
            </DialogTitle>
            <DialogContent>
                <Box sx={{ mt: 2 }}>
                    <TableContainer >
                        <Table sx={{ border: "1px solid #e1e1e1" }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell><Typography variant="h4" sx={{ color: "black", width: "140px" }}>Photo</Typography></TableCell>
                                    <TableCell>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                            <Typography variant="body2">
                                                {offer.photo ? (
                                                    <img src={`${API_URL}/${offer.photo}`} alt={offer.name} style={{ width: '100%', height: '100%' }} />
                                                ) : (
                                                    'No Photo'
                                                )}
                                            </Typography>
                                        </div>
                                    </TableCell>

                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography variant="h4" sx={{ color: "black", width: "140px" }}>Name</Typography></TableCell>
                                    <TableCell><Typography variant="body2">{offer.name}</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography variant="h4" sx={{ color: "black" }}>Created At</Typography></TableCell>
                                    <TableCell><Typography variant="body2">{offer.createdAt}</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography variant="h4" sx={{ color: "black" }}>Updated At</Typography></TableCell>
                                    <TableCell><Typography variant="body2">{offer.updatedAt}</Typography></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </DialogContent>
            <DialogActions>
                <div className="w-fit mb-2 px-4">
                    <Button
                        onClick={onClose}
                        variant="contained"
                        color="primary"
                        sx={{ py: 1, background: theme.palette.primary[800], color: "white" }}
                    >
                        Close
                    </Button>

                </div>
            </DialogActions>
        </Dialog>
    );
};

export default CategoryDetails;
