import React from 'react'

const Heading = ({ title }) => {
    return (
        <div>
            <h2 className="text-center font-medium text-gray-900" style={{fontSize:"18px"}}>{title}</h2>
        </div>
    )
}

export default Heading
