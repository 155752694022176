import axios from "axios"
import { API_URL } from "../constant"

export const getAllProducts = (pageNumber = 1, pageSize = 10, sortOrder = 'ASC', searchQuery, type) => {
    const params = new URLSearchParams({
        pageNumber,
        pageSize,
        sortOrder,
        searchQuery,
        type
    });
    return axios.get(`${API_URL}/api/products/list?${params.toString()}`);
};


export const getAllCategory = (pagination = true, pageNumber = 1, pageSize = 10, sortOrder = 'ASC', searchQuery) => {
    const params = new URLSearchParams({
        pageNumber,
        pageSize,
        sortOrder,
        searchQuery,
        pagination
    });
    return axios.get(`${API_URL}/api/category/list?${params.toString()}`);
};

export const getProductById = (id) => {
    const productById = axios.get(`${API_URL}/api/products/${id}`)
    return productById
}

export const getAffiliates = () => {
    const affiliate = axios.get(`${API_URL}/api/products/get/affiliate`)
    return affiliate
}

export const getCategoryById = (id) => {
    const categoryById = axios.get(`${API_URL}/api/category/${id}`)
    return categoryById
}

export const getBannerById = (id) => {
    const bannerId = axios.get(`${API_URL}/api/banner/${id}`)
    return bannerId
}

export const editProductById = (id, productData) => {
    return axios.put(`${API_URL}/api/products/${id}`, productData);
};


export const editCategoryById = (id, formData) => {
    return axios.put(`${API_URL}/api/category/${id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const createOffer = (productData) => {
    return axios.post(`${API_URL}/api/products/create`, productData);
};

export const deleteProductById = (id) => {
    return axios.delete(`${API_URL}/api/products/${id}`);
};

export const deleteCategoryById = (id) => {
    return axios.delete(`${API_URL}/api/category/${id}`);
};

export const createCategory = (formData) => {
    return axios.post(`${API_URL}/api/category/create`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
export const createBanner = (formData) => {
    return axios.post(`${API_URL}/api/banner/create`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
export const updateBanner = (id, formData) => {
    console.log("FormData",formData)
    return axios.put(`${API_URL}/api/banner/${id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const getBanners = () => {
    const banner = axios.get(`${API_URL}/api/banner`,)
    return banner
}

export const deletePhotoyId = (bannerId) => {
    return axios.delete(`${API_URL}/api/banner/${bannerId}/photos`);
};

export const deleteBannerById = (bannerId) => {
    return axios.delete(`${API_URL}/api/banner/${bannerId}`);
};